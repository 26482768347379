// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
  //   prop: 'id',
  //   label: '',
  //   Object: 'value',
  //   width: '150'
  // }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'period',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'discount_apply_to',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'orders_placed',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'details',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'status',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
